<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large>
          {{ icon }}
        </v-icon>
        ค้นหาข้อมูลลูกค้า
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          @click:append="searchData"
          @keydown.enter="searchData"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <!-- <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="exportToExcel()"
        >
          <v-icon> mdi-microsoft-excel </v-icon>
          Export to Excel
        </v-btn> -->
        <v-data-table
          :headers="headers"
          :items="customerDatas"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 1000] }"
          :loading="loading"
          :single-expand="true"
          loading-text="Loading..."
          :server-items-length="totalCustomerDatas"
          show-expand
          dense
          class="elevation-1"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
              <div class="font-weight-medium my-4">ข้อมูล CRM</div>
              <v-data-table
                :headers="customerCRMDatasHeaders"
                :items="item.customerCRMDatas"
                :single-expand="true"
                hide-default-footer
                show-expand
                dense
                class="elevation-2"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-2">
                    <v-data-table
                      :headers="crmDatasHeaders"
                      :items="item.crmDatas"
                      :single-expand="true"
                      hide-default-footer
                      dense
                      class="elevation-3"
                    >
                    </v-data-table>                   
                  </td>
                </template>
              </v-data-table>

              <div class="font-weight-medium my-4">ข้อมูล ใบแจ้งหนี้พิเศษ/CCL+SI</div>
              <v-data-table
                :headers="customerSpecialBillDatasHeaders"
                :items="item.customerSpecialBillDatas"
                :single-expand="true"
                hide-default-footer
                show-expand
                dense
                class="elevation-2"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-2">
                    <v-data-table
                      :headers="specialBillDatasHeaders"
                      :items="item.specialBillDatas"
                      :single-expand="true"
                      hide-default-footer
                      dense
                      class="elevation-3"
                    >
                    </v-data-table>                   
                  </td>
                </template>
              </v-data-table>

            </td>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/show'"
            >
              <v-icon> mdi-magnify </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
              small
              color="primary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/edit'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn> -->
            <!-- <v-btn
            small
            color="primary"
            class="ma-2"
            outlined
            :to="'/customer/' + item.id + '/delete'"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            Delete
          </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mdiTextBoxSearch } from "@mdi/js";

moment.locale("th");

export default {
  name: "CustomerList",
  components: {},
  props: {},
  data() {
    return {
      icon: mdiTextBoxSearch,
      search: "",
      totalCustomerDatas: 0,
      options: {},
      customerDatas: [],
      loading: false,
      headers: [
        {
          text: "ชื่อลูกค้า",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "ประเภทลูกค้า",
          value: "customerCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ศูนย์บริการลูกค้า",
          value: "officeCentre.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนขายและบริการลูกค้า",
          value: "officeCentre.officeSector.name",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      customerCRMDatasHeaders: [
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
        {
          text: "BA",
          value: "BA",
          sortable: false,
          align: "center",
        },
      ],
      crmDatasHeaders: [
        {
          text: "Name",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "Month",
          value: "month",
          sortable: false,
          align: "center",
        },
        {
          text: "Service Number",
          value: "serviceNum",
          sortable: false,
          align: "center",
        },
        {
          text: "Package",
          value: "bluePackage",
          sortable: false,
          align: "center",
        },
        {
          text: "Product",
          value: "blueProduct",
          sortable: false,
          align: "center",
        },
        {
          text: "Price",
          value: "price",
          sortable: false,
          align: "center",
        },
      ],
      customerSpecialBillDatasHeaders: [
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
      ],
      specialBillDatasHeaders: [
        {
          text: "Name",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "วันที่นำเข้าระบบ", //doujin
          value: "billIssueDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Service Number",
          value: "serviceNum",
          sortable: false,
          align: "center",
        },
        {
          text: "รหัสบริการ",
          value: "serviceCode",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "priceDetail",
          sortable: false,
          align: "center",
        },
        {
          text: "ราคา",
          value: "price",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนลด",
          value: "discount",
          sortable: false,
          align: "center",
        },
        {
          text: "ราคาหลังส่วนลด",
          value: "priceAfterDiscount",
          sortable: false,
          align: "center",
        },
        {
          text: "ภาษี",
          value: "tax",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนเงินรวม",
          value: "priceTotal",
          sortable: false,
          align: "center",
        },
        {
          text: "รหัสส่วนงานให้ออกบิล",
          value: "billIssueOfficeCode",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.search !== "") {
          this.getDataFromAPI();
        }
      },
      deep: true,
    },
    // search: {
    //   handler() {
    //     this.getDataFromAPI();
    //   },
    //   deep: true,
    // },
  },
  created() {},
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          with_crm_data: true,
          with_special_bill_data: true,
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerDatas = response.data.data;
          this.totalCustomerDatas = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    searchData() {
      this.customerDatas = [];
      this.totalCustomerDatas = 0;
      // this.options = {
      //   page: 1,
      //   per_page: 10
      // }
      this.getDataFromAPI();
    },
    // exportToExcel() {
    //   this.loading = true;
    //   const queryParams = {
    //     params: {
    //       search: this.search,
    //     },
    //     responseType: "arraybuffer",
    //   };
    //   axios
    //     .get("/export-to-excel/customer-use-service", queryParams)
    //     .then((response) => {
    //       // console.log(response);
    //       let blob = new Blob([response.data], {
    //         type: response.headers["content-type"],
    //       });
    //       let link = document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob);
    //       // console.log(response.headers);
    //       link.download = "BCRM_บริการที่ลูกค้าใช้งาน.xlsx";
    //       link.click();
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
